/*menu*/
body.grey-theme .app-sidebar--dark {
  background-color: #252835;
}

body.grey-theme .scrollbar-container .app-sidebar--userbox {
  background: #484D64;
}

body.grey-theme .app-sidebar--dark .sidebar-navigation ul li>a.active, body.grey-theme .app-sidebar--dark .sidebar-navigation ul li>a:hover {
  color: #fff;
  background: #484D64;
}

body.grey-theme .app-sidebar--dark .app-sidebar--footer {
  background: #484D64;
}

/*fundo*/
body.grey-theme .app-wrapper {
  background-color: #101214;
  color: #D9D9D9;
}

/*cabeçalho*/
body.grey-theme .app-header-fixed .app-header {
  background-color: hsl(229deg 18% 18% / 95%);
}

/*Rodapé ...*/
body.grey-theme .app-footer {
  background-color: hsl(229deg 18% 18% / 95%);
}

body.grey-theme .app-footer:hover {
  background-color: #252835 !important;
}

body.grey-theme .app-footer a {
  color: #C0C3CA !important;
}

body.grey-theme .app-footer a:hover {
  color: #2C87F2 !important;
}

/*Barra de Pesquisa header ...*/
body.grey-theme .search-wrapper.is-active .form-control {
  background-color: #484D64;
  border-color: #484D64;
  color: lightgray;
  box-shadow: none;
}

/*-----------------------------------------*/

body.grey-theme .w-100.row {
  color: #6b6b72 !important;
}

body.grey-theme .settings-desktop span {
  color: #c0c3ca69 !important;
}

body.grey-theme .app-page-title {
  background-color: #252835;
}

body.grey-theme .card {
  background-color: #252835 !important;
  border-color: #252835;
  color: #C0C3CA;
  box-shadow: 0 1rem 1rem 0 hsl(0deg 0% 0% / 15%), 0 0.3rem 1rem rgb(0 0 0 / 41%), 0 0.2rem 0.3rem rgb(0 0 0 / 54%) !important;
}

body.grey-theme .card:hover {
  background-color: #252835;
  color: #D1D4DB;
}

body.grey-theme .shadow-xxl {
  box-shadow: none !important;
}

/*calendario titulo*/
body.grey-theme .rbc-toolbar {
  background-color: #101214;
}

/*calendario dias dos meses não correntes*/
body.grey-theme .rbc-off-range-bg {
  background-color: #484D64;
}

/*calendario dia atual ...*/
body.grey-theme .rbc-today {
  background-color: #3C44B1 !important;
}

body.grey-theme .rbc-today:hover {
  background-color: #2C87F2 !important;
}

/*calendario...*/
body.grey-theme .rbc-current {
  color: white;
}

body.grey-theme .rbc-month-row:hover > div > .rbc-day-bg {
  background-color: rgba(14, 14, 16, 0.54);
}

body.grey-theme .rbc-month-row > div > .rbc-day-bg:hover {
  background-color: #000000;
}

body.grey-theme .popover-notification .popover-inner {
  background-color: #252835;
  box-shadow: 0 0.46875rem 2.1875rem rgb(0 0 0 / 15%), 0 0.9375rem 1.40625rem rgb(0 1 14 / 31%), 0 0.25rem 0.53125rem rgb(0 1 12 / 17%), 0 0.125rem 0.1875rem rgb(0 0 0 / 5%);
}

body.grey-theme .popover ul {
  border-right-color: #252835 !important;
}

body.grey-theme .rbc-toolbar button {
  border-color: transparent;
  background: transparent;
  color: #C0C3CA;
}

body.grey-theme .rbc-toolbar button:hover {
  background: #496C9F;
}

body.grey-theme .rdtDays tr > [class*="rdt"]:hover {
  background-color: #101214;
  border-radius: 7px;
}

body.grey-theme .rdtMonths tr > [class*="rdt"]:hover {
  background-color: #101214;
  border-radius: 7px;
}

body.grey-theme .rdtYears tr > [class*="rdt"]:hover {
  background-color: #101214;
  border-radius: 7px;
}

body.grey-theme .table-hover tr:hover {
  background-color: #101214;
}

body.grey-theme .react-bootstrap-table tr {
  background-color: #252835;
}

body.grey-theme .Toastify__toast {
  background-color: #484D64;
  color: #C0C3CA !important;
}

body.grey-theme .Toastify__toast a {
  color: #C0C3CA !important;
}

body.grey-theme .Toastify__close-button {
  color: #C0C3CA !important;
}

body.grey-theme .Toastify__toast.Toastify__toast--critical {
  background-color: #BD413D;
  color: #FFFFFF !important;
}

body.grey-theme .Toastify__toast.Toastify__toast--critical a,
body.grey-theme .Toastify__toast.Toastify__toast--critical p,
body.grey-theme .Toastify__close-button.Toastify__close-button--critical {
  color: #FFFFFF !important;
}

body.grey-theme .Toastify__progress-bar {
  background: #C0C3CA !important;
}

body.grey-theme .list-group-item {
  background-color: #252835 !important;
  border-color: #101214;
}

body.grey-theme .dropdown-menu {
  background-color: #484D64;
  box-shadow: 0 0.3rem 0.3rem rgba(0,0,0,0.3), 0 0rem 0.4rem rgba(0,0,0,0.3);
}

body.grey-theme .dropdown-modulos.dropdown-menu {
  background-color: #252835;
  box-shadow: 0 0.3rem 0.3rem rgba(0,0,0,0.3), 0 0rem 0.4rem rgba(0,0,0,0.3);
}

body.grey-theme .dropdown-menu > div > div > .divider {
  background-color: #252835 !important;
}

body.grey-theme .projetus-modal-content {
  background-color: #252835;
}

body.grey-theme .projetus-sucesso {
  background-image: linear-gradient(125deg, #484D64 -10%, #484D64 100%) !important
}

body.grey-theme .projetus-cancelar {
  background-image: linear-gradient(45deg, #420e0e, #8d0d07) !important;
}

body.grey-theme .svg-inline--fa.fa-search {
  color: white;
}

body.grey-theme .nav .nav-item .nav-link:hover {
  background-color: #101214;
}

body.grey-theme .nav.nav-tabs {
  border-color: #101214;
}

body.grey-theme .nav.nav-tabs .nav-item .nav-link {
  background: #484D64;
  border-color: #484D64;
}

@media screen and (max-width: 50em) {
  body.grey-theme .nav.nav-tabs .nav-item {
    border-color: #C0C3CA;
  }

  body.grey-theme .nav.nav-tabs .nav-item .nav-link {
    border-color: transparent;
  }
}

body.grey-theme .tabs-animated > .nav-tabs .nav-item .nav-link:before {
  background-color: #355d95 !important;
  box-shadow: 0 16px 26px -10px rgb(62 66 120 / 56%), 0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(60 68 177 / 20%);
}

body.grey-theme .btn-neutral-dark {
  background-color: #101214;
  color: #C0C3CA;
  border-color: #101214;
}

body.grey-theme .btn-neutral-second {
  background: rgb(0 0 0 / 52%);
  color: #cdcdcd;
  border-color: #ffffff94;
}

body.grey-theme .btn-neutral-dark:hover {
  color: #355d95;
}

body.grey-theme .form-control.form-control {
  background-color: #484D64;
  color: #ffffff;
  border-color: #484D64;
  box-shadow: none;
}

body.grey-theme .form-control.form-control.is-invalid {
  border-color: #F83245;
}

body.grey-theme .form-control.form-control-sm div {
  background-color: #101214;
  color: #ffffff;
  border-color: #101214;
}

body.grey-theme .action-bar-search .form-control {
  border-color: #484D64;
}

body.grey-theme .input-group-text {
  background-color: #484D64;
  color: #C0C3CA;
  border-color: #484D64;
}

body.grey-theme .input-group {
  border-color: #101214;
}

body.grey-theme .nav-link.custom-nav-button {
  background-color: transparent;
}

body.grey-theme .nav-link.custom-nav-button.disabled {
  background-color: #515151 !important;
  color: #747474 !important;
}

body.grey-theme .nav-link.custom-nav-button.active {
  background-color: #496C9F !important;
  color: #c0c3ca !important;
  border-top-color: #101214 !important;
  border-left-color: #101214 !important;
  border-right-color: #101214 !important;
}

body.grey-theme .nav-link.custom-nav-button:hover {
  background-color: #496C9F !important;
  color: #c0c3ca !important;
}

body.grey-theme .bg-menu {
  background-color: #101214 !important;
}

body.grey-theme .btn.btn-first {
  background: #101214 !important;
  border-color: #101214 !important;
}

body.grey-theme .btn-first:not(:disabled):focus, .btn-warning:not(:disabled):focus {
  box-shadow: none;
}

body.grey-theme .btn.btn-success {
  background: #496C9F !important;
  border-color: #496C9F !important;
}

body.grey-theme .btn.btn-success:active {
  background: #272C6C !important;
  border-color: #272C6C !important;
}

body.grey-theme .btn.btn-danger {
  background: #515151 !important;
  border-color: #515151 !important;
  color: #C0C3CA !important;
}

body.grey-theme .btn.btn-dark {
  background: #484D64 !important;
  border-color: #484D64 !important;
  color: #C0C3CA !important;
}

body.grey-theme .dropdown.show .dropdown-toggle:after {
  color: #3C44B1;
}

body.grey-theme .btn-dark:not(:disabled):focus {
  box-shadow: none;
}

body.grey-theme .btn-secondary {
  color: #f8f9ff;
  background-color: #6b6b72;
  border-color: #f8f9ff;
}

body.grey-theme .btn-secondary:hover {
  background-color: #f8f9ff;
  color: #343434;
}

body.grey-theme .btn-block.btn-neutral-dark.btn.btn-secondary {
  color: #C0C3CA;
  background-color: #3c44b1;
}

body.grey-theme .react-select-dropdown__single-value {
  color: #C0C3CA !important;
}

body.grey-theme .gt-thead .gt-th {
  background-color: #252835 !important;
  color: #C0C3CA;
}

body.grey-theme .table-striped tbody tr:hover {
  background-color: #10121466 !important;
}

body.grey-theme .table-striped tbody tr {
  background-color: #252835 !important;
  color: #C0C3CA;
}

body.grey-theme .gt-thead .gt-th:hover {
  background-color: #101214 !important;
  color: #FFFFFF !important;
}

body.grey-theme .grid-table td.gt-td, body.grey-theme .grid-table th.gt-th {
  border-color: #101214 !important;
}

body.grey-theme .grid-table tbody tr  {
  border: 1px solid #101214;
}

body.grey-theme .text-center.alert.alert-primary {
  background-color: #101214;
  color: #C0C3CA;
  border-color: #101214;
}

body.grey-theme .bg-secondary {
  background-color: #0000002b !important;
}

body.grey-theme .rc-pagination-item-active {
  background-color: #496C9F !important;
  border-color: #496C9F !important;
  box-shadow: 0 0.313rem 0.8rem rgb(0 0 0 / 47%), 0 0.126rem 0.225rem rgb(0 0 0 / 30%);
}

body.grey-theme .rc-pagination-item {
  background-color: transparent;
  border-color: #C0C3CA;
  color: #C0C3CA !important;
}

body.grey-theme .rc-pagination-item a {
  color: #C0C3CA !important;
}

body.grey-theme .rc-pagination-item:hover {
  background: #496C9F;
  border-color: #496C9F;
}

body.grey-theme .rc-pagination-item a:hover {
  color: #FFFFFF !important;
}

body.grey-theme .rc-pagination-prev:hover {
  background-color: #496C9F;
  border-color: #496C9F;
}

body.grey-theme .rc-pagination-disabled .rc-pagination-item-link:hover {
  cursor: not-allowed;
}

body.grey-theme .rc-pagination-next:hover {
  background-color: #496C9F;
  border-color: #496C9F;
}

body.grey-theme .rc-pagination-next {
  border-color: #C0C3CA;
}

body.grey-theme .rc-pagination-disabled {
  background-color: #515151;
  border-color: #747474 !important;
}

body.grey-theme .rc-pagination-disabled:hover {
  background-color: #515151;
  border-color: #747474 !important;
  box-shadow: none !important;
}

body.grey-theme .rc-pagination-disabled .rc-pagination-item-link {
  color: #6b6b72;
}

body.grey-theme .rc-select-selection.rc-select-selection--single {
  background-color: #484D64;
  color: #C0C3CA;
  border-color: #484D64;
}

body.grey-theme .rc-pagination-options-quick-jumper > input {
  background-color: #484D64;
  border-color: #484D64;
  color: #C0C3CA;
}

body.grey-theme .rc-select-dropdown {
  background-color: #484D64 !important;
  color: #C0C3CA !important;
  box-shadow: 0 0.46875rem 2.1875rem rgb(0 0 0 / 15%), 0 0.9375rem 1.40625rem rgb(0 1 14 / 31%), 0 0.25rem 0.53125rem rgb(0 1 12 / 17%), 0 0.125rem 0.1875rem rgb(0 0 0 / 5%);
}

body.grey-theme .rc-select-dropdown-menu-item.rc-select-dropdown-menu-item-selected {
  background-color: #101214 !important;
  color: #C0C3CA !important;
}

body.grey-theme .rc-select-dropdown-menu-item:hover {
  background-color: #252835 !important;
  color: #c0c3ca !important;
}

body.grey-theme .rc-select-dropdown-menu-item {
  color: #C0C3CA !important;
}

body.grey-theme .export.btn.btn-secondary {
  background-color: #101214;
  color: #3C44B1;
  border-color: #101214;
}

body.grey-theme .react-select-dropdown__menu {
  background-color: #484D64 !important;
}

body.grey-theme .modal-dialog > div > .modal-content {
  background-color: #484D64;
  border-color: #484D64;
  color: #C0C3CA;
  box-shadow: 0 5rem 14rem 0 hsl(0deg 0% 0% / 30%), 0 0.8rem 2.3rem rgb(0 0 0 / 60%), 0 0.2rem 0.3rem rgb(0 0 0 / 45%);
}

body.grey-theme .modal-content {
  background-color: #252835;
  box-shadow: 0 5rem 14rem 0 hsl(0deg 0% 0% / 30%), 0 0.8rem 2.3rem rgb(0 0 0 / 60%), 0 0.2rem 0.3rem rgb(0 0 0 / 45%);
}

body.grey-theme .modal-header {
  background-color: #252835;
  border-bottom: 1px solid #101214;
  border-top-left-radius: 0.65rem;
  border-top-right-radius: 0.65rem;
}

body.grey-theme .card-header {
  background-color: #252835 !important;
  border-bottom-color: #101214;
}

body.grey-theme .shadow-none.card {
  background-color: transparent !important;
}

body.grey-theme .shadow-none.card > .card-header {
  background-color: transparent !important;
}

body.grey-theme .shadow-none.card > .bg-light.card-header {
  background-color: #252835 !important;
}

body.grey-theme .edit-form .box-card {
  box-shadow: 0 1rem 1rem 0 hsl(0deg 0% 0% / 15%), 0 0.3rem 1rem rgb(0 0 0 / 41%), 0 0.2rem 0.3rem rgb(0 0 0 / 54%) !important;
}

body.grey-theme .rdtPicker {
  background-color: #252835;
  border-color: #252835;
  border-radius: 20px;
  box-shadow: 0 0.3rem 0.3rem rgba(0,0,0,0.3), 0 0rem 0.4rem rgba(0,0,0,0.3);
}

body.grey-theme .react-select--is-disabled,
body.grey-theme .react-select--is-disabled ~ div .input-group-text {
  background-color: #313137 !important;
  border-color: #313137 !important;
  color: #747474 !important;
}

body.grey-theme .react-select--is-disabled ~ div {
  border-color: #313137 !important;
  background: #313137;
}

body.grey-theme .react-select--is-disabled ~ div > button {
  background-color: #313137 !important;
  border-color: #313137 !important;
  border-left: 1px solid #747474 !important;
  color: #747474 !important;
  pointer-events: none;

}

body.grey-theme .table {
  border: #252835 !important;
}

body.grey-theme .react-select__single-value {
  color: #C0C3CA !important;
}

body.grey-theme .react-select__single-value--is-disabled {
  color: #747474 !important;
}

body.grey-theme .react-select__menu {
  background-color: #484D64 !important;
}

body.grey-theme .react-select__option:hover {
  background-color: #484D64 !important;
}

body.grey-theme .react-select__value-container div:last-child {
  color: #FFFFFF !important;
}

body.grey-theme .react-select-dropdown__option:hover {
  background-color: #484D64 !important;
}

body.grey-theme::-webkit-scrollbar, body.grey-theme ::-webkit-scrollbar,
body.grey-theme .popover-notification .ps__thumb-y {
  height: 10px;
  border-radius: 5px;
  width: 10px;
}

/* Track */
body.grey-theme::-webkit-scrollbar-track, body.grey-theme ::-webkit-scrollbar-track {
  box-shadow: none;
  background: #101214;
}

/* Handle */
body.grey-theme::-webkit-scrollbar-thumb, body.grey-theme ::-webkit-scrollbar-thumb,
body.grey-theme .popover-notification .ps__thumb-y {
  background: #484D64;
}

/* Handle on hover */
body.grey-theme::-webkit-scrollbar-thumb:hover, body.grey-theme ::-webkit-scrollbar-thumb:hover,
body.grey-theme .popover-notification .ps__thumb-y:hover {
  background: #496C9F;
}

body.grey-theme .bg-light.card-body {
  background-color: #252835 !important;
}

body.grey-theme .card-body span a {
  color: #3C44B1;
}

body.grey-theme .card-footer {
  background-color: #252835;
}

body.grey-theme .card-body span a:hover {
  color: #3C44B1;
}

body.grey-theme .accordion-toggle > button {
  color: #C0C3CA !important;
}

body.grey-theme .accordion-toggle > button:hover {
  color: #3C44B1 !important;
}

body.grey-theme .accordion-toggle > button.disabled {
  background: #515151 !important;
  color: #747474;
}

body.grey-theme .apexcharts-legend-text {
  color: #C0C3CA !important;
}

body.grey-theme .apexcharts-legend-text:hover {
  color: #3C44B1 !important;
}

body.grey-theme .react-select__multi-value {
  background-color: #496C9F;
}

body.grey-theme .react-select__multi-value__label {
  color: #ffffff;
}

body.grey-theme .custom-control-input:checked ~ .custom-control-label:before {
  background-color: #101214 !important;
  color: #496C9F;
  border: 1px solid #101214 !important;
}

body.grey-theme .custom-control-input:checked ~ .custom-control-label:after {
  background-color: #496C9F;
}

body.grey-theme .summer-checkbox .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  color: #C0C3CA;
  border-color: #39405C;
  background-color: #39405C;
}

body.grey-theme .summer-checkbox .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  border-radius: 0 !important;
  border: 1px solid #39405C !important;
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='7' viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.71715 6.86252L0.117152 3.81174C-0.0390508 3.62846 -0.0390508 3.33128 0.117152 3.14798L0.682825 2.48422C0.839028 2.30091 1.09231 2.30091 1.24851 2.48422L3 4.53935L6.75149 0.137464C6.90769 -0.0458212 7.16097 -0.0458212 7.31718 0.137464L7.88285 0.801228C8.03905 0.984513 8.03905 1.28169 7.88285 1.46499L3.28284 6.86254C3.12662 7.04582 2.87336 7.04582 2.71715 6.86252Z' fill='%23C0C3CA'/%3E%3C/svg%3E");
}

body.grey-theme .custom-control-label:before {
  background-color: #252835;
  border-color: #484D64;
}

body.grey-theme .custom-control-label:after {
  background-color: #484D64;
}

body.grey-theme .custom-checkbox .custom-control-label:after {
  background-color: transparent;
  border-radius: 0.25rem;
}

body.grey-theme .summer-checkbox .custom-checkbox .custom-control-label:before {
  border: 1px solid #355D95 !important;
  box-shadow: none !important;
}

body.grey-theme [disabled] ~ .custom-control-label:after {
  background-color: #6b6b72 !important;
}

body.grey-theme [disabled].custom-control-input:checked ~ .custom-control-label:after {
  background-color: #6b6b72 !important;
}

body.grey-theme .custom-control-input[disabled] ~ .custom-control-label:before {
  background-color: #313137 !important;
  color: #747474 !important;
  border-color: transparent !important;
}

body.grey-theme .react-select-dropdown--is-disabled {
  background-color: #313137 !important;
  border-color: #313137 !important;
  color: #747474 !important;
}

body.grey-theme .react-select-dropdown--is-disabled > div > div > .react-select-dropdown__indicator {
  color: #747474 !important;
}

body.grey-theme .react-select-dropdown--is-disabled > div > div > .react-select-dropdown__indicator-separator {
  background-color: #747474 !important;
}

body.grey-theme .react-date--is-disabled {
  background-color: #313137 !important;
  color: #747474;
  border-color: #313137 !important;
}

body.grey-theme .form-control[disabled] {
  color: #747474;
  background-color: #313137;
  border-color: #313137;
}

body.grey-theme .cc-1pvj.cc-y6ot {
  background-color: #252835 !important;
}

body.grey-theme .cc-g7iw.cc-2ydi input {
  background-color: #515151 !important;
  color: #C0C3CA !important;
}

body.grey-theme .company-box span {
  color: #c0c3ca69 !important;
}

body.grey-theme .text-black {
  color: #ffffff !important;
}

body.grey-theme .text-black-50 {
  color: #8a8b8f !important;
}

body.grey-theme .table.table-striped.table-bordered th {
  background-color: #101214 !important;
  border-color: #101214 !important;
  box-shadow: none;
}

body.grey-theme .grid-wrapper .table .gt-thead .gt-tr .gt-th {
  background: #101214 !important;
  box-shadow: inset 0 1px 0 #101214, inset 0 -1px 0 #101214;
}

body.grey-theme #company-selector-input [class$="menu"] {
  background-color: #252835;
  color: #ffffff;
  box-shadow: 0 5rem 14rem 0 hsl(0deg 0% 0% / 30%), 0 0.8rem 2.3rem rgb(0 0 0 / 60%), 0 0.2rem 0.3rem rgb(0 0 0 / 45%);
}

body.grey-theme #company-selector-input > div {
  background-color: #484D64;
  border-color: #484D64;
}

body.grey-theme .shadow-overflow:after {
  background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, #15151530 100%);
}

body.grey-theme .shadow-overflow:before {
  background: linear-gradient(180deg, #15151530 20%, hsl(0deg 0% 100% / 0%));
}

body.grey-theme .shadow-overflow span {
  color: rgba(213, 216, 223, 0.71);
}

body.grey-theme .modal-body {
  background-color: #252835;
  color: #C0C3CA !important;
  border-radius: 0.65rem;
}

body.grey-theme .modal-title {
  color: #C0C3CA !important;
}

body.grey-theme .modal-footer {
  background: #252835;
  border-top: none;
  border-bottom-left-radius: 0.65rem;
  border-bottom-right-radius: 0.65rem;
}

body.grey-theme .card-box {
  background-color: #252835;
  box-shadow: 0 1rem 1rem 0 hsl(0deg 0% 0% / 15%), 0 0.3rem 1rem rgb(0 0 0 / 41%), 0 0.2rem 0.3rem rgb(0 0 0 / 54%) !important;
}

body.grey-theme .button-menu-modulo .card-box {
  background-color: #484D64 !important;
  box-shadow: 0 1rem 1rem 0 hsl(0deg 0% 0% / 15%), 0 0.3rem 1rem rgb(0 0 0 / 41%), 0 0.2rem 0.3rem rgb(0 0 0 / 54%) !important;
}

body.grey-theme .card-box > div {
  color: #C0C3CA !important;
}

body.grey-theme .grid-pagination-desktop > div > .num-registros {
  color: #c0c3ca !important;
}

body.grey-theme .p-1.pl-2 > div {
  color: #C0C3CA !important;
}

body.grey-theme .custom-radio.custom-control .custom-control-input:checked ~ .custom-control-label:after {
  background-color: transparent !important;
}

body.grey-theme .custom-radio.custom-control .custom-control-label:after {
  background-color: transparent !important;
}

body.grey-theme .form-check-input {
  background-color: transparent !important;
}

body.grey-theme .steps-indicator > ul {
  background-color: transparent !important;
  box-shadow: transparent !important;
}

body.grey-theme .steps-indicator {
  background-color: #101214;
}

body.grey-theme .steps-indicator .card-box {
  box-shadow: 0 5rem 14rem 0 hsl(0deg 0% 0% / 0%), 0 0.8rem 2.3rem rgb(0 0 0 / 0%), 0 0.2rem 0.3rem rgb(0 0 0 / 0%) !important;
}

body.grey-theme label div:nth-child(1) {
  border: 3px solid #101214 !important;
}

body.grey-theme .promo-section-buttons {
  background: #101214;
  border: 1px solid #101214 !important;
  box-shadow: none;
}

body.grey-theme .promo-section-buttons .dock-button {
  border-color: transparent;
  color: #C0C3CA;
}

body.grey-theme .promo-section-buttons .dock-button:hover {
  background: #484D64 !important;
}

body.grey-theme .react-input-image-preview > label > div, body.grey-theme .react-input-image-preview > label > img {
  background: transparent;
  border: 2px solid #101214;
}

body.grey-theme .scrollbar-container > ul > li > div [class*="text"] {
  color: #C0C3CA !important;
}

body.grey-theme .badge-neutral-primary {
  background-color: #eceef752 !important;
}

body.grey-theme .dock-button {
  background-color: transparent !important;
}

body.grey-theme #projetus-alert-content {
  color: #9fa2a9 !important;
}

body.grey-theme .text-second {
  color: #515151 !important;
}

body.grey-theme .gt-th {
  background-color: #13192F !important;
}

body.grey-theme .label-credito label {
  color: #86a6df;
}

body.grey-theme .label-debito label {
  color: #ff5454;
}

body.grey-theme .label-diferenca label {
  color: #c0c3ca;
}

body.grey-theme .dropdown-filtros > .text-primary,
body.grey-theme .header-company-button > .text-primary,
body.grey-theme .dropdown-filtros .nav-item.active .nav-link {
  color: #C0C3CA !important;
}

body.grey-theme .dropdown-item.btn:hover {
  background-color: #272b3c8a !important;
}

body.grey-theme .dropdown-item.btn {
  color: #C0C3CA !important;
  background: transparent;
}

body.grey-theme #somaCredito {
  background-color: #4149d5 !important;
}

body.grey-theme #somaDebito {
  background-color: #6c0b0b !important;
}

body.grey-theme .react-select-dropdown__input {
  color: #FFFFFF;
}

body.grey-theme .steps-indicator > li {
  background-color: transparent !important;
}

body.grey-theme .badge-neutral-primary {
  background-color: #eceef70d !important;
}

body.grey-theme .text-primary {
  color: #0066ff !important;
}

body.grey-theme .semaforo > div > a {
  color: #0066ff;
}

body.grey-theme .calculo-totais input, body.grey-theme .consulta-calculo-wrapper [name="dataDemissaoDeterminado"] {
  color: #999999 !important;
}

body.grey-theme .popover-inner {
  background-color: #101214;
  border: 1px solid #101214;
}

body.grey-theme .popover {
  box-shadow: 0 0.313rem 0.8rem rgb(0 0 0 / 50%), 0 0.126rem 0.225rem rgb(0 0 0 / 30%);
}

body.grey-theme .btn-link {
  color: #2C87F2;
}

body.grey-theme .bg-light.text-dark {
  color: #ffffff !important;
  background-color: #101214 !important;
}

body.grey-theme .loading-animation {
  background: #101214;
  background: linear-gradient(to right, #252835 8%, #101214 18%, #252835 33%);
}

body.grey-theme .gt-tr.active {
  transition: none;
  background: #484d648c !important;
  color: #ffffff;
}

body.grey-theme .gt-tr.active:hover {
  transition: none;
  background: #101214 !important;
}

body.grey-theme input[type=checkbox]:checked,
body.grey-theme input[type=checkbox]:checked {
  accent-color: #496C9F !important;
  color: #ffffff;
  box-shadow: 0px 0px 0px 1px #496C9F;
}

body.grey-theme input[type=checkbox]:not(:checked),
body.grey-theme input[type=checkbox]:not(:checked) {
  box-shadow: 0px 0px 0px 1px #484D64;
  mix-blend-mode: color-burn;

}

body.grey-theme .button-header-app span {
  text-shadow: -1px 0px 0px rgb(174 180 247 / 62%);
}

body.grey-theme .header-sticky > thead > tr > th {
  box-shadow: inset 0 1px 0 #2b3758, inset 0 -2px 0 #2d395c;
}

body.grey-theme #SiteQualificacaoCadastral {
  color: #4191ff;
}

body.grey-theme .MuiTreeItem-root.Mui-expanded.Mui-selected {
  background-color: #484D64;
}

body.grey-theme .MuiTreeItem-root.Mui-expanded .MuiTreeItem-content:hover {
  background-color: #101214;
}

body.grey-theme .suspense-loading {
  background-color: #252835;
}

body.grey-theme .dropdown-item:focus, body.grey-theme .dropdown-item:hover {
  color: #C0C3CA;
  background-color: #355d95;
}

body.grey-theme .popover-list .list-item {
  border-top: solid 0 !important;
}

body.grey-theme .def-modal-button-wrapper .close {
  color: #ffffff;
}

body.grey-theme .close:not(:disabled):not(.disabled):hover {
  color: #75787D;
}

body.grey-theme .scroll-area-sm.shadow-overflow {
  border: 1px solid #101214;
}

body.grey-theme .react-select__indicator.react-select__dropdown-indicator {
  color: #C0C3CA;
}

body.grey-theme .react-select--is-disabled .react-select__indicator.react-select__dropdown-indicator {
  color: #747474 !important;
}

body.grey-theme .react-select--is-disabled .react-select__indicator-separator {
  background-color: #747474;
}

body.grey-theme .react-select-dropdown__indicator.react-select-dropdown__dropdown-indicator {
  color: #C0C3CA !important;
}

body.grey-theme .react-select__indicator.react-select__clear-indicator {
  color: #FF0000;
}

body.grey-theme .react-select__indicator.react-select__dropdown-indicator:hover {
  color: #496C9F !important;
}

body.grey-theme .react-select-dropdown__indicator.react-select-dropdown__dropdown-indicator:hover {
  color: #496C9F !important;
}

body.grey-theme .react-select__indicator.react-select__clear-indicator:hover {
  color: #6c0b0b;
}

body.grey-theme .react-select-dropdown__indicator.react-select-dropdown__clear-indicator {
  color: red;
}

body.grey-theme .react-select-dropdown__indicator.react-select-dropdown__clear-indicator:hover {
  color: #6c0b0b;
}

body.grey-theme .app-inner-content-layout--main .card-body {
  background: #252835;
}

body.grey-theme #company-selector-input [class$="control"] > [class$="ValueContainer"] > [class$="Input"] > div input {
  color: #ffffff !important;
}

body.grey-theme #company-selector-input div > div > div > div input {
  color: #ffffff !important;
}

body.grey-theme {
  background: #101214;
}

body.grey-theme .form-control div > .rdt.form-control {
  background: transparent;
  box-shadow: none;
}

body.grey-theme .alert-dark {
  background: #333548;
  color: #C0C3CA;
  border-color: #39405c;
}

body.grey-theme .alert-primary {
  background: #101214;
  color: #C0C3CA;
  border-color: #101214;
}

body.grey-theme .grid-wrapper .loading-wrapper {
  background: rgba(0, 0, 0, 0.7);
  color: #C0C3CA;
}

body.grey-theme .evento-simplificado .text-primary {
  color: #2aaee3 !important;
}

@media only screen and (min-width: 801px) {
  body.grey-theme .table-bordered, body.grey-theme .table-bordered td, body.grey-theme .table-bordered th {
    border: 1px solid #101214;
  }
}

body.grey-theme .btn.btn-outline-primary.btn-sm {
  color: #c0c3ca;
  border-color: #101214;
  background: #101214;
}

body.grey-theme .btn.btn-outline-primary.btn-sm.disabled {
  color: #6b6b72;
  border-color: #6b6b72;
  background: #333548;
}

body.grey-theme .object-key-val {
  background: #252835;
}

body.grey-theme .object-key {
  color: #51a7ff !important;
}

body.grey-theme .tabs-animated>.nav-tabs .nav-item .nav-link {
  border: 1px solid #101214 !important;
}

body.grey-theme .file-item > svg {
  color: #3c44b1;
}

body.grey-theme [class*="btn-menu-"] > div > img {
  filter: none;
}

body.grey-theme .btn-menu-site > div > .img-modulo {
  filter: brightness(6);
}

body.grey-theme .btn-secondary:not(:disabled):active,
body.grey-theme .btn-secondary:not(:disabled):focus,
body.grey-theme .btn-secondary:not(:disabled):hover {
  box-shadow: none;
}

body.grey-theme .app-header-menu > div > button:focus,
body.grey-theme .app-header-menu > div > button:active,
body.grey-theme #companySelectorPopover:focus,
body.grey-theme #companySelectorPopover:active {
  color: #2C87F2;
}

@media (max-width: 580px) {
  body.grey-theme .search-wrapper.is-active .form-control {
    background-color: #0000006e;
    color: lightgray;
    border: none;
    box-shadow: none;
  }
}

@media (min-width: 581px) {
  body.grey-theme .bg-menu-modulos {
    border: 1px solid #252835 !important;
  }

  body.grey-theme .app-header-fixed .app-header:hover {
    background-color: #252835 !important;
  }

}

body.grey-theme .menu-apps-popover > div > div > div > .card {
  background: #252835 !important;
}

body.grey-theme .menu-apps-popover > div > div > .card {
  background: #484D64 !important;
}

body.grey-theme .menu-apps-popover > div > div {
  background: transparent !important;
  border: 0 !important;
}

body.grey-theme .menu-apps-popover > div > div > div > .card > div > button:last-child {
  color: #f8f9ff !important;
}

body.grey-theme .button-header-app > div {
  color: #fff;
  background: #101214;
}

body.grey-theme .pe-7s-download {
  color: #fff;
}

body.grey-theme [id*='buttonView']:hover > span > svg > path {
  fill: #2b87f2 !important;
}

body.grey-theme .menu-mobile-name,
body.grey-theme [id*='buttonView'] > span > svg,
body.grey-theme .menu-descktop-name,
body.grey-theme .variable-row span,
body.grey-theme .plano-conta-icon > svg,
body.grey-theme p,
body.grey-theme .table.table-striped.table-bordered,
body.grey-theme h6,
body.grey-theme .edit-form,
body.grey-theme .dropdown-menu.dropdown-menu-right.show,
body.grey-theme .rc-pagination-item-link,
body.grey-theme .nav .nav-item .nav-link,
body.grey-theme .settings-desktop,
body.grey-theme .label,
body.grey-theme label.custom-control-label,
body.grey-theme .app-header-menu > div > button,
body.grey-theme #companySelectorPopover,
body.grey-theme #alertsPopover,
body.grey-theme #menuAppsPopover,
body.grey-theme #open-crisp,
body.grey-theme #user-dropdown-config,
body.grey-theme #open-videos,
body.grey-theme .text-notification-deleted {
  color: #C0C3CA;
}

body.grey-theme #alertsPopover,
body.grey-theme #menuAppsPopover,
body.grey-theme #open-crisp,
body.grey-theme #user-dropdown-config,
body.grey-theme #open-videos {
  background: #101214;
  border-color: #101214;
}

body.grey-theme .modal-header > button {
  color: #C0C3CA;
  text-shadow: none;
}

body.grey-theme [id*='buttonView'],
body.grey-theme .apexcharts-canvas.apexcharts-theme-dark,
body.grey-theme .button-header-app.btn.btn-secondary {
  background: transparent;
}

body.grey-theme .tree-modulo-style .react-checkbox-tree.rct-native-display > ol > li > span {
  background: #252835;
}

body.grey-theme .quick-access-popover .nav-link.selected {
  background-color: #51515136;
}

body.grey-theme .global-search a:hover,
body.grey-theme .global-search a.selected {
  background-color: transparent;
}

body.grey-theme .btn.btn-link.btn-habilitar-mais-tarde {
  background-color: #F83245 !important;
  border-color: #F83245 !important;
  color: #C0C3CA !important;
}

body.grey-theme .bg-notification {
  background: #101214;
}

body.grey-theme .btn-clear-notifications,
body.grey-theme .btn-clear-notifications:hover,
body.grey-theme .btn-clear-notifications:active,
body.grey-theme .btn-toggle-downloads,
body.grey-theme .btn-toggle-downloads:hover,
body.grey-theme .btn-toggle-downloads:active{
  background-color: #101214 !important;
  border-color: #101214 !important;
}

body.grey-theme .card-notification {
  background-color: #101214 !important;
  border-color: #101214 !important;
  box-shadow: 0 1rem 1rem 0 hsl(0deg 0% 0% / 15%), 0 0.3rem 1rem rgb(0 0 0 / 41%), 0 0.2rem 0.3rem rgb(0 0 0 / 54%) !important;
}

body.grey-theme .copy-notification {
  color: #4191FF;
}

body.grey-theme .remove-notification {
  background-color: transparent;
}

body.grey-theme .remove-notification svg path {
  fill: #C0C3CA;
}

body.grey-theme .notification-tabs {
  background: #101214;
}

body.grey-theme .notification-tabs .tab {
  background: #496C9F;
}

body.grey-theme .notification-tabs label div {
  border: 0 !important;
  color: #C0C3CA;
}

body.grey-theme #toggle-processos div {
  background-color: rgba(192, 195, 202, 0.2);
}

body.grey-theme .screenshot-button:hover {
  background: #C0C3CA;
  color: #FFF;
}

body.grey-theme .screenshot-button {
  color: #C0C3CA;
}

body.grey-theme .screenshot-button.screenshot-button-border {
  border: 1px solid #C0C3CA;
}

body.grey-theme .screenshot-button .screenshot-button-spinner {
  border: 1px solid #C0C3CA;
  border-right-color: transparent;
}

body.grey-theme .user-apps-mobile > a {
  background: rgba(14, 14, 16, 0.47) !important;
}

body.grey-theme [class*='label-empresa-mobile-'] {
  background: #252835;
}

body.grey-theme .carousel-control-prev-icon,
body.grey-theme .carousel-control-next-icon,
body.grey-theme .carousel-indicators {
  filter: drop-shadow(2px 4px 6px black);
}

body.grey-theme .close {
  color: #fff;
}

body.grey-theme .alert-danger {
  background-color: #101214;
  border-color: #101214;
}

body.grey-theme .alert-warning {
  color: #fff;
  background-color: #515151;
  border-color: #515151;
}

body.grey-theme .action-bar-search-box .btn {
  color: #C0C3CA !important;
  background: #484D64 !important;
  border-color: #484D64 !important;
  border-left-color: #C0C3CA !important;
}

body.grey-theme .rct-node-icon .icon-modulo-site {
  filter: invert();
}

body.grey-theme .btn-outline-danger:hover,
body.grey-theme .btn-outline-success:hover,
body.grey-theme .btn-outline-warning:hover,
body.grey-theme .btn-outline-danger:active,
body.grey-theme .btn-outline-success:active,
body.grey-theme .btn-outline-warning:active {
  background: #101214 !important;
  border-color: #101214 !important;
  color: #496C9F !important;
}

body.grey-theme .btn-outline-success,
body.grey-theme .btn-link.text-primary,
body.grey-theme .btn-link.text-primary:hover,
body.grey-theme .btn-link.text-success,
body.grey-theme .btn-link.text-success:hover {
  color: #496C9F !important;
}

body.grey-theme .btn-outline-warning,
body.grey-theme .btn-link.text-warning,
body.grey-theme .btn-link.text-warning:hover {
  color: #484D64 !important;
}

body.grey-theme .btn-outline-danger,
body.grey-theme .btn-link.text-danger {
  color: #87898E !important;
}

body.grey-theme .evento-simplificado .tr-exclusao,
body.grey-theme .evento-simplificado .tr-exclusao:hover {
  background-color: #2F1324 !important;
}

body.grey-theme .btn-link.text-danger.btn-toggle-linha-disabled {
  color: #3B3E66 !important;
}

body.grey-theme .propaganda-certificado a {
  color: #C0C3CA;
}

body.grey-theme .dash-card-info {
  background: #101214;
  border: none;
}

body.grey-theme .dash-card-info > div > a {
  color: #3c44ad;
}


body.grey-theme .modal-certificado div .modal-body h2 {
  color: #C0C3CA;
}
body.grey-theme .modal-certificado div .modal-body .alert-certificado {
  background: #101214;
  border: 0;
}

body.grey-theme .modal-certificado div .modal-body #select-certificado {
  background: #101214;
  border: 0;
}

body.grey-theme .modal-certificado div .modal-body #select-certificado > span {
  color: #C0C3CA;
}

body.grey-theme .modal-certificado div .modal-body #idsenha input:not(:disabled) {
  background: #101214;
  border: 0;
}

body.grey-theme .importacao-notas button.importar:disabled {
  background: #515151;
  color: #747474;
}

body.grey-theme .full-tab-container {
  background: #39405C;
}

body.grey-theme .full-tab-container .active-selector {
  background: #3C44B1;
}

body.grey-theme .full-tab-container .nav-item div {
  color: #C0C3CA !important;
}

body.grey-theme .full-tab-container .nav-item div.disabled {
  color: #71747E !important;
}

body.grey-theme .custom-radio.custom-control .custom-control-label .radio-relatorio {
  border: 0 !important;
}

body.grey-theme .custom-radio.custom-control .custom-control-label .radio-relatorio div {
  background: #252835 !important;
}

body.grey-theme .custom-radio.custom-control .custom-control-label .radio-relatorio div div {
  background: #3C44B1 !important;
  border-color: #3C44B1 !important;
}

body.grey-theme .custom-radio.custom-control .custom-control-label .radio-relatorio div div svg {
  margin-bottom: 3px;
}

body.grey-theme .text-primary div div {
  color: #c0c3ca !important;
}

body.grey-theme [id*=row-radio-] .custom-radio .custom-control-label:before {
  border-color: #484D64;
}

body.grey-theme .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image:  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='%23496C9F'/%3e%3c/svg%3e");
}

body.grey-theme .input-search-select > [class$="-menu"] > [class$="-MenuList"] > [class$="-option"]:hover {
  background: #101214 !important;
}

body.grey-theme .app-sidebar--shadow {
  box-shadow: 0rem 0 0rem rgb(0,0,0), 0.3rem 0 1rem rgb(0 0 0 / 10%), 0.3rem 0 1rem rgb(0 0 0 / 10%), 0.2rem 0 0.3rem rgb(0 0 0 / 42%) !important;
}

body.grey-theme .app-footer--shadow {
  box-shadow: none !important;
}

body.grey-theme .input-search-dropdown-filter {
  border-color: #484D64;
  background: #484D64;
}

body.grey-theme .input-search-dropdown-filter > button {
  border-radius: 0 !important;
  background: #484D64;
  border-color: #484D64;
  color: #c0c3ca;
  border-left: 1px solid #c0c3ca;
}

body.grey-theme .input-search-dropdown-filter > button:hover {
  background: #484D64;
  color: #c0c3ca;
}

body.grey-theme .input-group > .input-group-append,
body.grey-theme .input-group > .input-group-append > .input-group-text {
  border-left: 1px solid #c0c3ca !important;
}

body.grey-theme .input-group .react-select--is-disabled ~ .input-group-append > .input-group-text {
  border-left: 1px solid #747474 !important;
}

body.grey-theme .alertContainer-modal .modal-content .modal-footer .first_button {
  background-color: #496C9F;
  border-color: #496C9F;
  color: #c0c3ca;
}

body.grey-theme .alertContainer-modal .modal-content .modal-footer .second_button {
  background-color: #999EAE;
  border-color: #999EAE;
  color: #101214;
}

body.grey-theme .modal-content > .modal-body > .progress-bar-rounded.progress-animated-alt.progress > .progress-bar.progress-bar-animated.bg-first.progress-bar-striped {
  background-color: #496C9F !important;
}

body.grey-theme .modal-backdrop {
  background-color: #101214 !important;
}

body.grey-theme .fade.show .tooltip.show > div {
  background-color: #000000 !important;
  box-shadow: 0 1rem 1rem 0 hsl(0deg 0% 0% / 15%), 0 0.3rem 1rem rgb(0 0 0 / 41%), 0 0.2rem 0.3rem rgb(0 0 0 / 54%) !important;
}

body.grey-theme .fade.show .popover.show {
  background-color: #252835 !important;
  box-shadow: 0 1rem 1rem 0 hsl(0deg 0% 0% / 15%), 0 0.3rem 1rem rgb(0 0 0 / 41%), 0 0.2rem 0.3rem rgb(0 0 0 / 54%) !important;
}

body.grey-theme .fade.show .popover.show .popover-inner .block-picker > div:last-child {
  background-color: #252835 !important;
}

body.grey-theme .fade.show .popover.show .popover-inner .block-picker > div:last-child > div > span > div {
  border: 1px solid #c0c3ca;
}

body.grey-theme .fade.show .popover.show .popover-inner .block-picker > div:last-child > div > input {
  background: #484D64;
  box-shadow: none !important;
  color: #c0c3ca !important;
}

body.grey-theme .danfeButton > svg {
  color: #3C44B1 !important;
}

body.grey-theme .danfeButton > span {
  color: #3C44B1 !important;
}

body.grey-theme .danfeButton.disabled > svg {
  color: #747474 !important;
}

body.grey-theme .danfeButton.disabled > span {
  color: #747474 !important;
}

/*sidebar Modal*/
body.grey-theme .modal-sidebar-version .modal-body {
  background-color: #252835;
  color: #c0c3ca !important;
}

body.grey-theme .modal-sidebar-version h6 {
  color: #515151;
}

body.grey-theme .modal-sidebar-version .close,
body.grey-theme .modal-sidebar-version .close:hover {
  color: #101214 !important;
}

body.grey-theme .tour-container .tour-content {
  color: #FFF;
  background: #252835;
}

body.grey-theme .tour-container .arrow-left:before {
  border-right-color: #252835;
}

body.grey-theme .tour-container .arrow-right:before {
  border-left-color: #252835;
}

body.grey-theme .tour-container .arrow-top:before {
  border-bottom-color: #252835;
}

body.grey-theme .tour-container .arrow-top-end:before {
  border-bottom-color: #252835;
}

body.grey-theme .tour-container .steps .step {
  background: #101214;
}

body.grey-theme .grid-form-table .rdtPicker {
  background: #101214 !important;
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%) !important;
  border: 1px solid #101214 !important;
}

body.grey-theme .grid-form-table .rdtPicker td,
body.grey-theme .grid-form-table .rdtPicker th {
  background-color: #101214 !important;
}

body.grey-theme .grid-form-table .table-striped .rdtPicker tbody tr:nth-of-type(odd) {
  background-color: #101214 !important;
}

body.grey-theme .grid-form-table .rdtPicker .rdtTimeToggle:hover,
body.grey-theme .grid-form-table .rdtPicker td.rdtDay:hover,
body.grey-theme .grid-form-table .rdtPicker td.rdtHour:hover,
body.grey-theme .grid-form-table .rdtPicker td.rdtMinute:hover,
body.grey-theme .grid-form-table .rdtPicker td.rdtSecond:hover,
body.grey-theme .grid-form-table .rdtPicker thead tr:first-child th:hover,
body.grey-theme .grid-form-table td.rdtMonth:hover,
body.grey-theme .grid-form-table td.rdtYear:hover {
  background: #484D64 !important;
}

body.grey-theme .grid-form-table .rdtPicker td.rdtActive,
body.grey-theme .grid-form-table .rdtPicker td.rdtActive:hover {
  background-color: #3C44B1 !important;
  color: #fff !important;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 25%) !important;
}

body.grey-theme .bs-popover-auto[x-placement^=bottom]>.arrow:after, body.grey-theme .bs-popover-bottom>.arrow:after {
  border-bottom-color: #101214 !important;
}

body.grey-theme .bs-popover-auto[x-placement^=right]>.arrow:after, body.grey-theme .bs-popover-right>.arrow:after {
  border-right-color: #101214 !important;
}

body.grey-theme label div:nth-child(1).tema {
  border: none !important;
}

body.grey-theme .radio-temas .tema span {
  color: #C0C3CA;
}

body.grey-theme label div:nth-child(1).color {
  border: none !important;
}

body.grey-theme .radio-temas .summer-input-radio input:checked + label .tema .color {
  outline: solid 3px #101214;
}

body.grey-theme .table-striped tbody tr.bg-aviso {
  background-color: #48321D !important;
  border: 1px solid #48321D;
}

body.grey-theme .alert-aviso-header {
  color: #C0C3CA !important;
}


body.grey-theme .modal-pesquisa-nps .modal-header {
  border-bottom: none;
}

body.grey-theme .modal-pesquisa-nps .pesquisa-nps h2,
body.grey-theme .modal-pesquisa-nps .pesquisa-nps h4,
body.grey-theme .modal-pesquisa-nps .pesquisa-nps .box-comentario label {
  color: #fff;
}

body.grey-theme .modal-pesquisa-nps .pesquisa-nps .box-comentario textarea {
  background-color: #252835;
  box-shadow: none;
  border-color: #C0C3CA;
}

body.grey-theme .modal-pesquisa-nps.modal-done h4.done {
  color: #fff;
}

body.grey-theme .card-integra .totalizador-container {
  background-color: #101214;
}

body.grey-theme .card-integra .divider-totalizador {
  background: #484D64;
}

body.grey-theme .dashboard-pro-text {
  color: #C0C3CA;
}

body.grey-theme .btn-acessar-nitrus,
body.grey-theme .btn-acessar-nitrus:hover,
body.grey-theme .btn-acessar-nitrus:active,
body.grey-theme .btn-acessar-nitrus:focus {
  background: #999EAE !important;
  color: #101214 !important;
}

body.grey-theme .btn-link-dashboard-pro,
body.grey-theme .btn-link-dashboard-pro:hover,
body.grey-theme .btn-link-dashboard-pro:active,
body.grey-theme .btn-link-dashboard-pro:focus {
  background: transparent !important;
  color: #C0C3CA !important;
}

body.grey-theme #toggle-pesquisa-avancada {
  position: relative;
  z-index: 2;
}

@media (max-width: 900px) {
  body.grey-theme .dashboard-pro-container {
    background: #252835;
  }
}

body.grey-theme .btn-primary {
  color: #101214 !important;
  background-color: #999EAE !important;
  border-color: #999EAE !important;
}

body.grey-theme .btn-primary:hover {
  background-color: #999EAE !important;
  color: #101214 !important;
}

body.grey-theme .gerar-guia-mic {
  color: #C0C3CA;
}

body.grey-theme .CustomTree .MuiTreeItem-root.Mui-expanded .MuiTreeItem-group .MuiTreeItem-content {
  background-color: #101214 !important;
}

body.grey-theme .CustomTree .MuiTreeItem-root.Mui-expanded .MuiTreeItem-group .MuiTreeItem-content:hover {
  background-color: #252835 !important;
}

body.grey-theme .CustomTree .MuiTreeItem-root > .MuiTreeItem-content:hover {
  background-color: #484d64 !important;
}

body.grey-theme .CustomTree .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content,
body.grey-theme .CustomTree .MuiTreeItem-root.Mui-expanded > .MuiTreeItem-content,
body.grey-theme .CustomTree .MuiTreeItem-group .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content {
  background-color: #484d64 !important;
}

body.grey-theme .wrapper-simple > .card-header .icon-container button:not(.close-window-modal) {
  background-color: #101214;
}

body.grey-theme .wrapper-simple > .card-header .icon-container button:not(.close-window-modal):not(.wiki-button) {
  color: #C0C3CA;
}

body.grey-theme .wrapper-simple > .card-header .icon-container button:not(.close-window-modal):not(.wiki-button).active {
  color: #0011FF;
}

body.grey-theme .wrapper-simple > .card-header .icon-container button:not(.close-window-modal):not(.wiki-button):hover {
  background-color: #0011FF;
  color: #C0C3CA;
}

body.grey-theme .quick-access-popover .empty {
  color: #C0C3CA;
}

body.grey-theme .quick-access-popover footer span {
  color: #C0C3CA;
}

body.grey-theme .quick-access-popover footer button {
  color: #C0C3CA;
}

body.grey-theme .cancelar-todos {
  color: #fff !important;
}

body.grey-theme .box-alert {
  background: #484D64;
  border-color: #496C9F;
}

body.grey-theme .file-selector.card .dragdrop-area .title,
body.grey-theme .file-selector.card label,
body.grey-theme .file-selector.card .files li>div span,
body.grey-theme .file-uploader-progress .info {
  color: #C0C3CA;
}

body.grey-theme .file-selector.card .dragdrop-area {
  border-color: #101214;
}

body.grey-theme .file-selector.card .dragdrop-area.drag-over {
  border-color: #595965;
}

body.grey-theme .file-selector.card .files li {
  background-color: #484D64;
}

body.grey-theme .card.calculo-folha {
  box-shadow: none !important;
}

body.grey-theme .icon-gerar-guia-fgts-digital {
  color: #3C44B1;
}

body.grey-theme .icon-baixar-guia-fgts-digital {
  color: #2C87F2;
}

body.grey-theme .icon-visualizar-parametrizadas-digital {
  fill: #496C9F;
}

body.grey-theme .icon-consultar-pagamento-fgts-digital {
  color: #3B3E66;
}

body.grey-theme .comentario-projetus {
  background: #101214;
  color: #C0C3CA;
}

body.grey-theme .comentario-cliente {
  background: #484D64;
}

body.grey-theme .comentario-cliente div,
body.grey-theme .comentario-cliente strong,
body.grey-theme .comentario-cliente .comentario-data,
body.grey-theme .comentario-cliente button,
body.grey-theme .comentario-projetus button {
  color: #C0C3CA;
}

body.grey-theme [id*=row-radio-] .custom-radio .custom-control-input:checked ~ .custom-control-label:before {
  background-color: transparent !important;
}

body.grey-theme .summer-input-radio.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='%235C5E60'/%3e%3c/svg%3e");
}

body.grey-theme [id*=row-radio-] .custom-radio .custom-control-input:disabled ~ .custom-control-label:before {
  border-color: #5C5E60 !important;
  background-color: transparent !important;
}

body.grey-theme .summer-label-radio-disabled {
  color: #5C5E60;
}

body.grey-theme .custom-slider::-webkit-slider-thumb {
  background: #496C9F;
}
body.grey-theme .progress-box {
  border: none;
  background-color: #101214;
}
