.modal-propaganda-integra-contador.modal-window {
  background-color: transparent;
  aspect-ratio: 1.5399;
}

.modal-propaganda-integra-contador .slide {
  width: 100%;
  display: inline-block;
  position: relative;
  vertical-align: top;
  height: 86%;
}

.modal-propaganda-integra-contador .slide img {
  width: 100%;
  border-top-left-radius: 48px;
  border-top-right-radius: 48px;
  position: absolute;
  top: 0;
  /*opacity: 0;*/
  transition: opacity .75s;
  z-index: 1;
}

.modal-propaganda-integra-contador .slide img.visible {
  opacity: 1;
}

.modal-propaganda-integra-contador.modal-window button.close-modal {
  background: #068373;
  color: #EEEEEE;
  padding: 3px 0 0 2px;
  box-shadow: rgba(0, 0, 0, .25) 0 4px 4px;
}

.modal-propaganda-integra-contador .ad-footer button.btn-prosseguir {
  background: #D1D1D1;
  border: none;
  color: #253053;
  font-size: 24px;
  padding: 7px 30px;
  border-radius: 15px;
  outline: none;
  width: 49%;
}

.modal-propaganda-integra-contador .ad-footer button.btn-conhecer {
  background: #068373;
  border: none;
  color: #FFFFFF;
  font-size: 32px;
  padding: 7px 30px;
  border-radius: 15px;
  outline: none;
  width: 49%;
}

.modal-propaganda-integra-contador .area {
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  height: 100%;
  top: 0;
  cursor: pointer;
  border: none;
  background: transparent;
  outline: none;
}

.modal-propaganda-integra-contador .area-prev {
  left: 0;
}

.modal-propaganda-integra-contador .area-next {
  right: 0;
}

.modal-propaganda-integra-contador .slide .area > div {
  background: rgba(217, 217, 217, 0.75);
  border-radius: 15px;
  color: #253053;
  font-size: 40px;
  margin: 0 30px;
  width: 50px;
  height: 55px;
}

.modal-propaganda-integra-contador .slide .area-next > div {
  padding: 0 0 0 5px;
}

.modal-propaganda-integra-contador .slide .area-prev > div {
  padding: 0 5px 0 0;
}

.modal-propaganda-integra-contador .ad-footer {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  height: 16%;
  justify-content: space-evenly;
  background-color: #E7E8EF;
  flex-direction: column;
  border-bottom-left-radius: 48px;
  border-bottom-right-radius: 48px;
}

.modal-propaganda-integra-contador .ad-footer .btns {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.modal-propaganda-integra-contador .ad-footer a {
  text-decoration: none;
  font-size: 18px;
  display: inline-block;
  vertical-align: top;
  width: 49%;
}

.modal-propaganda-integra-contador .ad-footer button {
  background-color: #068373;
  border: none;
  color: #ffffff;
  font-size: 24px;
  padding: 7px 30px;
  border-radius: 15px;
  outline: none;
  width: 100%;

}

@media screen and (max-width: 1272px) {
  .modal-propaganda-integra-contador .ad-footer a button.btn-prosseguir {
    font-size: 20px;
  }
  .modal-propaganda-integra-contador .ad-footer button.btn-conhecer {
    font-size: 28px;
  }
}

@media screen and (max-width: 1142px) {
  .modal-propaganda-integra-contador .ad-footer a {
    font-size: 14px;
  }

  .modal-propaganda-integra-contador .ad-footer button.btn-prosseguir {
    font-size: 16px;
  }

  .modal-propaganda-integra-contador .ad-footer a button.btn-conhecer {
    font-size: 24px;
  }
}

@media screen and (max-width: 1011px) {
  .modal-propaganda-integra-contador .ad-footer a {
    font-size: 14px;
  }

  .modal-propaganda-integra-contador .ad-footer {
    flex-direction: column;
    height: 16%;
    justify-content: space-around;
  }

  .modal-propaganda-integra-contador .ad-footer button.btn-prosseguir {
    font-size: 14px;
  }

  .modal-propaganda-integra-contador .ad-footer button {
    font-size: 14px;
  }
}



@media screen and (max-height: 618px) {
  .modal-propaganda-integra-contador .ad-footer a {
    font-size: 14px;
  }

  .modal-propaganda-integra-contador .ad-footer {
    flex-direction: column;
    height: 16%;
    justify-content: space-around;
  }

  .modal-propaganda-integra-contador .ad-footer button.btn-prosseguir {
    font-size: 14px;
  }

  .modal-propaganda-integra-contador .ad-footer button {
    font-size: 14px;
  }
}
